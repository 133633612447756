import request from '../utils/request';
import qs from "qs";

export const list = param => {
	return request.post('/api/record/list', qs.stringify(param))
};
export const updateRecord = param => {
	return request.post('/api/record/updateRecord', qs.stringify(param))
};
export const delRecord = id => {
	return request.get('/api/record/delete?id=' + id)
};

export const findByRecordId = data => {
	return request.get('/api/record/findByRecordId?id=' + data.id + '&&currentUserId=' + data.currentUserId)
};

export const download = id => {
	return request.get('/api/record/download?url=' + id, {
		responseType: 'blob'
	})
};


export const intentionCustomerList = param => {
	return request.post('/api/intentionCustomer/list', qs.stringify(param))
};

export const findRecordingPath = param => {
	return request.post('/api/record/findRecordingPath', qs.stringify(param))
};

export const downloadIntentionCustomerList = param => {
	return request.post('/api/intentionCustomer/downloadIntentionCustomer', qs.stringify(param), {
		responseType: 'blob'
	})
};

export const addIntentionCustomer = param => {
	return request.post('/api/intentionCustomer/addIntentionCustomer', param, {
		headers: {
			'Content-Type': 'application/json'
		}
	})
};
export const updateIntentionCustomer = param => {
	return request.post('/api/intentionCustomer/updateIntentionCustomer', param, {
		headers: {
			'Content-Type': 'application/json'
		}
	})
};
export const updateSchedule = param => {
	return request.post('/api/intentionCustomer/updateSchedule', qs.stringify(param))
};
export const intentionCustomerFindDetails = id => {
	return request.get('/api/intentionCustomer/findDetails?id=' + id)
};
export const intentionCustomergetDetails = id => {
	return request.get('/api/intentionCustomer/getDetails?id=' + id)
};
export const getfindCallRecord = id => {
	return request.get('/api/intentionCustomer/findCallRecord?id=' + id)
};
//释放客户（新增批量）
export const deleteIntentionCustomer = param => {
	return request.post('/api/intentionCustomer/deleteIntentionCustomer', qs.stringify(param))
};

export const GetIntentionCustomeraddJournal = param => {
	return request.post('/api/intentionCustomer/addJournal', qs.stringify(param))
};

export const recorddownloadRecord = param => {
	return request.post('/api/record/downloadRecord', qs.stringify(param), {
		responseType: 'blob'
	})
};

export const recordLabel = param => {
	return request.post('/api/record/recordLabel', qs.stringify(param))
};

export const CRMpublicData = param => {
	return request.post('/api/intentionCustomer/publicData', qs.stringify(param))
};
//领取（分配）crm公海数据
export const getPublicData = param => {
	return request.post('/api/intentionCustomer/getPublicData', qs.stringify(param))
};
//扭转客户
export const turnaroundCustomer = param => {
	return request.post('/api/intentionCustomer/turnaroundCustomer', qs.stringify(param))
};

//公海客户删除
export const removeCrm = param => {
	return request.post('/api/intentionCustomer/removeCrm', qs.stringify(param))
};
